 /* src/index.css */

.App {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .media-frontpage {
    margin-bottom: 40px;
  }
  
  .media-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .article {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 4px 8px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .article-title {
    font-size: 18px;
    margin-top: 0;
  }
  
  .article-author {
    font-size: 14px;
    color: #666;
  }
  
  .article-date {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
  
  .article-text {
    font-size: 14px;
  }

  .article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-gap: 20px;
  }

  .article-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  
  .divider {
    margin: 20px 0;
    border: none;
    border-top: 4px solid #dddddd;
  }

  .promo-item {
    position: relative;
    overflow: hidden;
  }
  
  .promo-item img {
    width: 100%;
    transition: transform 0s ease-in-out;
  }
  
  .promo-item::before { /* Create a pseudo-element for the overlay */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4; /* Initially invisible */
    transition: opacity 0.3s ease-in-out;
  }
  
  .promo-item:hover::before { /* Show the overlay on hover */
    opacity: 0.5; /* Adjust this value to control the fading effect */
    
  }
  
  .promo-item:hover img {
    transform: scale(1.1); /* Increase this value to adjust the fade effect */
    opacity: 0.5; /* Adjust this value to control the fading effect */
  }
  
  .promo-item .promo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  
  .svg-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Make sure the SVG container spans the entire width */
    max-width: 100%; /* Set a maximum width for responsiveness */
    overflow: hidden; /* Hide any overflowing content */
  }
  
  
  /* For smaller screens (mobile devices) */
@media (max-width: 768px) {
  .svg-container {
    width: 100%; /* Adjust width to your preference */
    max-width: none; /* Reset the maximum width */
  }

  .svg-content {
    width: 100%; /* Adjust width to your preference */
    height: auto;
  }
  
  
}

  